const addLeadingZero = (number) => {
  return number < 10 ? `0${number}` : `${number}`;
};

const allFirstCharToUpperCase = (text) => {
  const regex = /\b\w/g;
  return text.replace(regex, (char) => char.toUpperCase());
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const detectCurrentPath = (currentPath, pathname) => {
  const pathLastSegment = currentPath.split("/").slice(-1)[0];

  return currentPath === pathname || pathLastSegment === pathname;
};

export { addLeadingZero, allFirstCharToUpperCase, delay, detectCurrentPath };
