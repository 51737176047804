import { bodyWidth, theme } from "../../utils/theme";

const button = {
  borderRadius: 50,
  fontSize: {
    xs: theme.typography.fontSize * 0.75,
    md: theme.typography.fontSize * 0.88,
  },
  mb: { xs: 4, md: 0 },
  mr: { xs: 0, sm: 2, md: 6 },
  pb: { xs: 2, md: 3 },
  pl: 2,
  pr: 2,
  pt: { xs: 2, md: 3 },
  textTransform: "capitalize",
  width: { xs: "100%", sm: 240 },
};

const bubble = {
  alignItems: "center",
  borderRadius: "50%",
  color: theme.palette.grays.white,
  display: "flex",
  filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25))",
  fontWeight: 500,
  fontSize: theme.typography.fontSize * 0.7,
  justifyContent: "center",
  position: "absolute",
  zIndex: 1,
};

export const styles = {
  buttonPrimary: {
    ...button,
  },
  buttonSecondary: {
    ...button,
    borderColor: theme.palette.grays[400],
    color: theme.palette.grays.white,
    "&:hover": {
      color: theme.palette.grays[400],
    },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  heroAction: {
    alignItems: "center",
    display: "flex",
    flexDirection: { xs: "column", sm: "row", md: "row" },
    justifyContent: "start",
    width: "100%",
  },
  heroBackground: {
    // background:
    //   "linear-gradient(300deg, rgba(4, 104, 214, 0.34) 1.09%, rgba(225, 150, 198, 0.34) 32.33%, rgba(255, 190, 170, 0.34) 59.9%)",
    backgroundPosition: "left top",
    backgroundRepeat: { xs: "no-repeat", md: "no-repeat" },
    backgroundSize: { xs: "cover", md: "cover" },
    filter: "brightness(1)",
    height: { xs: "100%", sm: "100%", md: "100%" },
    top: { xs: -4, sm: -4, md: -4 },
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  heroBody: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    gap: 12,
    height: { xs: "68%", sm: "100%", md: "100%" },
    justifyContent: { xs: "start", sm: "center" },
    width: { xs: "92%", sm: "44%" },
    zIndex: 2,
  },
  heroContainer: {
    alignItems: "center",
    backgroundColor: theme.palette.grays.black,
    display: "flex",
    flexDirection: "column",
    height: { xs: "100vh", sm: 620, md: 800, lg: 960 },
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  heroForeground: {
    alignItems: "center",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: { xs: "column", sm: "row-reverse" },
    gap: 4,
    height: "100%",
    justifyContent: "center",

    zIndex: 2,
  },
  heroHeaderContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: 2,
    justifyContent: "start",
  },
  heroHeaderLarge: {
    alignSelf: "end",
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.fontSize * 2.2,
      md: theme.typography.fontSize * 3,
    },
    fontWeight: 900,
    letterSpacing: 1.2,
    lineHeight: 1,
    textAlign: "start",
    textTransform: "capitalize",
  },
  heroHeaderSmall: {
    alignSelf: "end",
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.fontSize * 1.4,
      md: theme.typography.fontSize * 1.9,
    },
    fontWeight: 900,
    letterSpacing: 1.2,
    lineHeight: 1.1,
    textAlign: "start",
    textTransform: "capitalize",
  },
  heroImage1: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "96%",
    left: 28,
    bottom: 44,
    position: "absolute",
    width: "96%",
    zIndex: 1,
  },
  heroImage2: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "37%",
    left: -8,
    top: "35%",
    position: "absolute",
    width: "37%",
    zIndex: 1,
  },
  heroImage3: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "52%",
    right: -35,
    bottom: 100,
    position: "absolute",
    width: "52%",
    zIndex: 1,
  },
  heroImage4: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "33%",
    left: 120,
    top: "18%",
    position: "absolute",
    width: "33%",
    zIndex: 1,
  },
  heroImageBackground: {
    backgroundPosition: { xs: "bottom", sm: "center" },
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "142%",
    position: "absolute",
    right: -50,
    top: -290,
    width: "142%",
    zIndex: 0,
  },
  heroImageBackgroundMobile: {
    backgroundPosition: { xs: "bottom", sm: "center" },
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  heroImageBubble1: {
    ...bubble,
    backgroundColor: "#FFB300",
    height: 44,
    left: -24,
    top: "36%",
    width: 44,
  },
  heroImageBubble2: {
    ...bubble,
    backgroundColor: "#EA4335",
    height: 56,
    left: 108,
    bottom: "30%",
    width: 56,
  },
  heroImageBubble3: {
    ...bubble,
    backgroundColor: "#34A853",
    height: 64,
    right: -24,
    top: "40%",
    width: 64,
  },
  heroImageContainer: {
    height: { xs: 540, md: "100%" },
    position: "relative",
    width: { xs: "92%", sm: "48%" },
    zIndex: 1,
  },
  heroSlogan: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.fontSize * 0.75,
      md: theme.typography.fontSize * 1.5,
    },
    fontWeight: 700,
    letterSpacing: 2,
    lineHeight: 1.2,
    mb: 0,
    position: { xs: "relative", md: "absolute" },
    left: { xs: 0, md: 48 },
    textAlign: "center",
    textTransform: "uppercase",
    whiteSpace: "pre-line",
    width: { xs: "100%", md: 256 },
  },
  heroSloganContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    height: { xs: "100%", md: 280 },
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  heroSubSlogan: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.fontSize * 2,
      md: theme.typography.fontSize * 3.75,
    },
    fontWeight: 700,
    letterSpacing: 2,
    lineHeight: { xs: 1.2, md: 1.2 },
    position: { xs: "relative", md: "absolute" },
    right: { xs: 0, md: 72 },
    textAlign: "center",
    textTransform: "uppercase",
    whiteSpace: "pre-line",
    width: { xs: "100%", md: 300 },
  },
  heroText: {
    color: theme.palette.grays[300],
    fontSize: {
      xs: theme.typography.fontSize * 0.88,
      lg: theme.typography.fontSize * 1.1,
    },
    fontWeight: 500,
    textAlign: "start",
  },
  heroVideo: {
    height: "100%",
    left: 0,
    position: "absolute",
    width: "100%",
  },
  heroWave: {
    height: "11%",
    bottom: -12,
    position: "absolute",
    width: "100%",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  video: {
    filter: "brightness(1.3) grayscale(0.4) hue-rotate(0deg)",
    height: "100%",
    left: 0,
    minWidth: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    width: "auto",
  },
  videoContainer: {
    height: { xs: "100vh", md: "100%" },
    overflow: "hidden",
    position: "absolute",
    top: 0,
    width: "100vw",
  },
};
