import contact_bg from "../../assets/background/events_bg.jpg";
import { theme } from "../../utils/theme";

const HEADER_HEIGHT = { xs: 330, sm: 330 };

export const styles = {
  background: {
    // backgroundColor: theme.palette.grays['500'],
    filter: "brightness(1.5) opacity(0.9)",
    height: HEADER_HEIGHT,
    left: { xs: 0, sm: 0 },
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 1,
  },
  backgroundOverlay: {
    background: `url(${contact_bg})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    filter: "brightness(1) hue-rotate(230deg) opacity(0.3)",
    height: { xs: "120%", sm: "150%" },
    position: "absolute",
    right: { xs: -180, sm: 0 },
    top:{xs: -48, sm: -100},
    width: { xs: "200%", sm: "100%" },
  },
  body: {
    alignItems: { xs: "center", md: "center" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    width: "100%",
  },
  bodyText: {
    color: theme.palette.grays[600],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.08,
    },
    fontWeight: 400,
    textAlign: { xs: "center", md: "center" },
    textTransform: "none",
  },
  gridContainer: {
    alignItems: "start",
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  header: {
    alignItems: { xs: "center", sm: "center" },
    height: HEADER_HEIGHT,
    position: "relative",
    width: { xs: "100%", md: "100%" },
  },
  headerText: {
    fontSize: {
      xs: theme.typography.fontSize * 3,
      sm: theme.typography.fontSize * 4,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: { xs: 1, md: 1.1 },
    textAlign: { xs: "center", md: "center" },
    textTransform: "capitalize",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  nullContainer: {
    alignItems: "center",
    color: theme.palette.grays[600],
    display: "flex",
    fontSize: theme.typography.fontSize * 1,
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  root: {
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  section: {
    mb: { xs: 2, md: 20 },
    mt: { xs: 2, md: 20 },
    width: "100%",
  },
};
