import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";

import { GlobalContext } from "../../contexts/GlobalContext";
import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const Product = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const [setRef, visible] = useInView({});
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (visible) {
      dispatch({
        type: "SET_CURRENT_VIEW",
        payload: NAV_SUBROUTES.get("PRODUCT").VALUE,
      });
    }
  }, [visible]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={styles.productRoot}>
      <SectionLayout id={NAV_SUBROUTES.get("PRODUCT").ID}>
        <Header data={content.product} />

        <Stack ref={setRef} spacing={16} sx={{ mt: 8, width: "100%" }}>
          {content.product?.items.map((item, index) => {
            return (
              <Box key={index}>
                <Box
                  sx={{
                    ...styles.item,
                    flexDirection: {
                      xs: "column",
                      md: index % 2 === 0 ? "row" : "row-reverse",
                    },
                  }}
                >
                  <video
                    src={item.video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      width: matches ? "100%" : "50%",
                      height: "auto",
                      objectFit: "cover",
                      aspectRatio: "16 / 9",
                    }}
                  />

                  <Stack sx={styles.listContainer}>
                    <Typography sx={styles.listHeader}>
                      {t(item.header)}
                    </Typography>

                    {item.list_items.map((ele, idx) => (
                      <Box key={idx} sx={styles.listItem}>
                        <Box sx={styles.listBullet} />
                        <Typography sx={styles.listItemText}>
                          {t(ele)}
                        </Typography>
                      </Box>
                    ))}

                    <Button
                      component={Link}
                      sx={{
                        ...styles.button,
                        backgroundColor: item.button.color,
                        "&:hover": {
                          backgroundColor: item.button.color,
                          filter: "brightness(0.7)",
                        },
                      }}
                      to={item.button.link}
                      variant="contained"
                    >
                      {t(item.button.label)}
                    </Button>

                    {matches && content.product?.items.length !== index + 1 && (
                      <Divider sx={{ mt: 8, width: "100%" }} />
                    )}
                  </Stack>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </SectionLayout>
    </Box>
  );
};

export default Product;
