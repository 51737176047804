import { AppBar, Container, Slide, Toolbar } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";

import Logo from "../../../components/Logo";
import NavList from "../NavList";
import NavMenu from "../NavMenu";

import { theme } from "../../../utils/theme";

import { styles } from "./style";

const Y_THRESHOLD = 100;

const Nav = () => {
  const [posY, setPosY] = useState();

  const getPosition = () => {
    const y = window.scrollY;

    setPosY(y);
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", getPosition);

    return () => {
      window.removeEventListener("scroll", getPosition);
    };
  }, []);

  return (
    // <>
    //   {posY < Y_THRESHOLD || !posY ? (
    //     <AppBar elevation={0} position="sticky" sx={styles.navDefault}>
    //       <Toolbar sx={styles.toolbar}>
    //         <Logo />

    //         <NavList />

    //         <NavMenu />
    //       </Toolbar>
    //     </AppBar>
    //   ) : (
    <AppBar elevation={0} position="sticky" sx={styles.navContained}>
      <Container maxWidth="xl">
        <Toolbar sx={styles.toolbar}>
          <Logo />

          <NavList />

          <NavMenu />
        </Toolbar>
      </Container>
    </AppBar>
    //   )}
    // </>
  );
};

export default Nav;
