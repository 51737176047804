import {
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";

import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const Value = () => {
  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSelect = (input) => () => {
    setValue(input);
    setIsSelectMenuOpen(false);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={styles.valueRoot}>
      <SectionLayout id={NAV_SUBROUTES.get("VALUE").ID}>
        <Header data={content.value} />

        {/* Tabs (Desktop) */}
        {/* <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={styles.tabs}
        >
          {content.value.items.map((item, index) => {
            return (
              <Tab
                key={index}
                label={t(item.label)}
                disableRipple
                sx={styles.tab}
              />
            );
          })}
        </Tabs> */}

        {/* Dropdown Select (Mobile) */}
        {/* <Box sx={styles.selectFormContainer}>
          <Box
            sx={styles.selectButton}
            onClick={() => setIsSelectMenuOpen(!isSelectMenuOpen)}
          >
            <Typography sx={styles.selectFormValue}>
              {t(
                content.value.items.find((f) => {
                  if (f.value === value) return f;
                }).label
              )}
            </Typography>

            <Box sx={styles.grow} />

            {isSelectMenuOpen ? (
              <RiArrowUpSLine size="1.2em" />
            ) : (
              <RiArrowDownSLine size="1.2em" />
            )}
          </Box>

          {isSelectMenuOpen && (
            <Box sx={styles.selectMenu}>
              {content.value.items.map((option, index) => {
                return (
                  <Box
                    sx={styles.selectOption}
                    key={index}
                    onClick={handleChangeSelect(option.value)}
                  >
                    <Typography sx={styles.selectOptionLabel}>
                      {t(option.label)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box> */}

        {/* Carousel */}
        <Box ref={setRef} sx={styles.body}>
          <Swiper
            modules={[Pagination, Navigation]}
            pagination={{ clickable: true }}
            navigation
            loop
            style={{ paddingBottom: "48px" }}
          >
            {content.value.items.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Box sx={styles.content}>
                    <img
                      alt={item.label}
                      src={item.image}
                      style={{
                        ...styles.contentImageContainer,
                        width: matches ? "100%" : "70%",
                      }}
                    />

                    <Stack
                      sx={{
                        ...styles.listContainer,
                        backgroundColor: item.backgroundColor,
                      }}
                    >
                      <Typography sx={styles.listHeader}>
                        {t(item.label)}
                      </Typography>

                      {item.unorderedItems.map((ele, idx) => (
                        <Box key={idx} sx={styles.listItem}>
                          <Box sx={styles.listBullet} />
                          <Typography sx={styles.listItemText}>
                            {t(ele)}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </SectionLayout>
    </Box>
  );
};

export default Value;
