import { theme } from "../../utils/theme";

export const styles = {
  button: {
    borderRadius: 50,
    fontSize: {
      xs: theme.typography.fontSize * 0.75,
      md: theme.typography.fontSize * 0.88,
    },
    mt: { xs: 4, md: 8 },
    pb: { xs: 3, md: 3 },
    pl: 2,
    pr: 2,
    pt: { xs: 3, md: 3 },
    textTransform: "capitalize",
    width: { xs: "100%", sm: 240 },
  },
  background: {
    left: { xs: "-120%", md: "-100%" },
    position: "absolute",
    top: { xs: "-8%", md: "-24%" },
    zIndex: -1,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  item: {
    alignItems: "center",
    display: "flex",
    gap: { xs: 2, sm: 14 },
    justifyContent: "start",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  listBullet: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    minHeight: 4,
    ml: { xs: 2, md: 2 },
    mr: { xs: 4, md: 4 },
    mt: { xs: 1.4, md: 2.4 },
    minWidth: 4,
  },
  listContainer: {
    height: "100%",
    justifyContent: { xs: "start", sm: "center" },
    pb: 3,
    pt: { xs: 4, md: 8 },
    width: { xs: "100%", md: "50%" },
  },
  listHeader: {
    fontSize: {
      xs: theme.typography.fontSize * 1.5,
      md: theme.typography.fontSize * 1.5,
    },
    fontWeight: 700,
    letterSpacing: 1.2,
    lineHeight: 1.1,
    mb: { xs: 5, md: 5 },
    textTransform: "capitalize",
  },
  listItem: {
    alignItems: "flex-start",
    display: "flex",
    mb: { xs: 3, md: 3 },
  },
  listItemText: {
    color: theme.palette.grays[700],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.13,
    },
    lineHeight: 1.5,
  },
  productItems: {
    width: "100%",
  },
  productRoot: {
    alignItems: "center",
    color: theme.palette.grays.black,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  tab: {
    color: theme.palette.grays[500],
    fontSize: {
      xs: theme.typography.fontSize * 0.88,
      md: theme.typography.fontSize * 1.13,
    },
    fontWeight: 300,
    pl: { xs: 0, md: 2 },
    pr: { xs: 0, md: 2 },
    textTransform: "capitalize",
    "&.Mui-selected": {
      color: theme.palette.grays.black,
      fontWeight: 700,
    },
  },
  tabs: {
    borderBottomColor: theme.palette.grays[300],
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: { xs: "flex", md: "flex" },
    justifyContent: "space-between",
    mb: { xs: 8, md: 8 },
    width: { xs: "100%", md: "108%" },
  },
};
