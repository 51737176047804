import { theme } from "../../utils/theme";

import contact_bg from "../../assets/background/contact_bg.png";

export const styles = {
  backgroundOverlay: {
    background: `url(${contact_bg})`,
    backgroundPosition: "start !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: { xs: "contain !important", sm: "contain !important" },
    top: 0,
    height: "101%",
    left: { xs: 0, sm: 0 },
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  body: {
    alignItems: "center",
    display: "flex",
    justifyContent: "start",
    position: "relative",
  },
  bodyText: {
    color: theme.palette.grays[300],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.13,
    },
    fontWeight: 400,
    textAlign: { xs: "start", sm: "start" },
    textTransform: "none",
  },
  button: {
    color: theme.palette.grays.white,
    transition: "all 0.5s ease-in-out",
    width: { xs: "100%", md: "fit-content" },
  },
  contactContainer: {
    alignItems: { xs: "center", md: "end" },
    position: "relative",
    width: "100%",
    zIndex: 1,
  },
  contactRoot: {
    alignItems: { xs: "center", md: "center" },
    backgroundColor: "#010612",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  header: {
    alignItems: { xs: "start", sm: "start" },
  },
  headerText: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.fontSize * 3,
      sm: theme.typography.fontSize * 3,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: { xs: 1, md: 1 },
    textAlign: { xs: "start", sm: "start" },
    textTransform: "capitalize",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
