import { useState, useEffect } from "react";
import { RiArrowUpSLine } from "react-icons/ri";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300); // Show button when scrolled 300px
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      onClick={scrollToTop}
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        border: "none",
        borderRadius: "5px",
        bottom: "20px",
        color: "#fff",
        cursor: "pointer",
        display: isVisible ? "block" : "none",
        fontSize: "16px",
        padding: "10px 13px",
        position: "fixed",
        right: "20px",
        zIndex: 999,
      }}
    >
      <RiArrowUpSLine size="1.4em" />
    </button>
  );
};

export default ScrollToTopButton;
