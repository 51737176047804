import {
  RiFacebookCircleFill,
  RiLinkedinBoxFill,
  RiTwitterFill,
} from "react-icons/ri";
import { v4 as uuid } from "uuid";

/**
 * ABOUT IMPORTS
 */
import about_image_en from "../assets/images/about_image_en.png";
import about_image_cn from "../assets/images/about_image_cn.png";

/**
 * BLOG IMPORTS
 */
import blog_24_jan_2025 from "../assets/blog/blog_24_jan_2025.jpg";
import blog_02_jan_2025 from "../assets/blog/blog_02_jan_2025.jpg";
import blog_24_dec_2024 from "../assets/blog/blog_24_dec_2024.png";
import blog_16_dec_2024 from "../assets/blog/blog_16_dec_2024.png";

/**
 * CUSTOMERS IMPORTS
 */
import value_1 from "../assets/images/value_1.png";
import value_2 from "../assets/images/value_2.png";
import value_3 from "../assets/images/value_3.png";
import value_4 from "../assets/images/value_4.png";

/**
 * EVENTS IMPORTS
 */
import cncc_2024_thumbnail from "../assets/events/cncc_2024/cncc_2024_thumbnail.png";
import techinnovation_2024_thumbnail from "../assets/events/techinnovation_2024/techinnovation_2024_thumbnail.png";
import singapore_fintech_festival_2024_thumbnail from "../assets/events/singapore_fintech_festival_2024/singapore_fintech_festival_2024_thumbnail.png";

import cncc_2024_content from "../markdown/events/cncc_2024_content.md";
import singapore_fintech_festival_2024_content from "../markdown/events/singapore_fintech_festival_2024_content.md";
import techinnovation_2024_content from "../markdown/events/techinnovation_2024_content.md";

/**
 * OVERVIEW IMPORTS
 */
import overview_1 from "../assets/images/overview_1.png";
import overview_2 from "../assets/images/overview_2.png";
import overview_3 from "../assets/images/overview_3.png";
import overview_4 from "../assets/images/overview_4.png";
import overview_video from "../assets/videos/overview_video.mp4";
import overview_video_chinese from "../assets/videos/overview_video_chinese.mp4";

/**
 * PRODUCTS IMPORTS
 */

import marketing_en_slides_01 from "../assets/product/marketing_en/slides_01.png";
import marketing_en_slides_02 from "../assets/product/marketing_en/slides_02.png";
import marketing_en_slides_03 from "../assets/product/marketing_en/slides_03.png";
import marketing_en_slides_04 from "../assets/product/marketing_en/slides_04.png";
import marketing_en_slides_05 from "../assets/product/marketing_en/slides_05.png";

import marketing_en_video_01 from "../assets/product/videos/01-ipms-tech-offer-generation-en-2503.mp4";
import marketing_en_video_02 from "../assets/product/videos/02-ipms-tech-report.mp4";
import marketing_en_video_03 from "../assets/product/videos/03-ipcp-ip8chat-en-2503.mp4";
import marketing_en_video_04 from "../assets/product/videos/04-ipms-demand-analysis-en-2503.mp4";

/**
 * PARTNERS IMPORTS
 */
import partners_nus from "../assets/partners/nus_logo.jpg";
import partners_nusdbsys from "../assets/partners/nusdbsystem_logo.png";
import partners_soc from "../assets/partners/soc_logo.jpg";
import partners_sbip from "../assets/partners/sbip_logo.png";

import solution_image from "../assets/images/solution_image.png";
import tech_image from "../assets/images/tech_image.png";

/**
 * TECH IMPORTS
 */
import block_icon from "../assets/images/block_icon.png";
import contract_icon from "../assets/images/contract_icon.png";
import lock_icon from "../assets/images/lock_icon.png";
import nft_icon from "../assets/images/nft_icon.png";
import search_icon from "../assets/icons/search_icon.png";
import { theme } from "./theme";
import { NAV_SUBROUTES } from "./routes";

export const content = {
  about: {
    header: "About Us",
    image: {
      en: about_image_en,
      cn: about_image_cn,
    },
    items: [
      "One-click import of technology disclosures, papers, patent, etc.",
      "Tailored private technology database setup for efficient IP management",
      "Intelligent generation of customized marketing stragegies and tech offers",
      "Automated technology analysis",
      "AI-powered technology Q&A system",
      "Proactive customer search and approach",
    ],
    itemHeader: "Steamlined  •  Comprehensive  •  Secure  •  Collaborative",
    subheader: "Who we are",
    content:
      "IP8Value, founded by leading researchers and IP experts, combines cutting-edge technology translation expertise with advanced AIxDB research to empower clients in effortlessly managing research outcomes and accelerating success in innovation and technology commercialization.",
  },
  blog: {
    header: "Blog",
    image: "",
    items: [
      {
        link: {
          en: "https://mailchi.mp/fff44c956ab5/ip8value-newsletter-ai-for-medical-image",
          cn: "https://mp.weixin.qq.com/s/mutPWQbZ48TsOgFDRVnszQ",
        },
        content:
          "The global healthcare sector is witnessing a significant surge in demand for efficient and accurate diagnostic tools, driven by the increasing prevalence of chronic diseases and the growing geriatric population. The market for artificial intelligence (AI) in medical imaging is projected to reach US$20.9 billion by 2030, with the Asia Pacific region alone accounting for around US$769 million.",
        date: "24 January 2025",
        id: `_${uuid()}`,
        thumbnail: blog_24_jan_2025,
        title: "Artificial Intelligence in Medical Imaging",
      },
      {
        link: {
          en: "https://mailchi.mp/2e2b825eb893/ip8value-newsletter-autonomous-system",
          cn: "https://mp.weixin.qq.com/s/V29NT-Qis6q5puVL5th3KQ",
        },
        content:
          "The increasing demand for automation and robotics in various industries has created a significant opportunity for innovative technologies to transform the way we live and work. The global market for autonomous systems and robotics is expected to reach $214 billion by 2025, growing at a compound annual growth rate (CAGR) of 24.1%. This rapid growth is driven by the need for improved efficiency, safety, and productivity in industries such as manufacturing, logistics, healthcare, and agriculture.",
        date: "2 January 2025",
        id: `_${uuid()}`,
        thumbnail: blog_02_jan_2025,
        title: "Autonomous Systems and Robotics",
      },
      {
        link: {
          en: "https://mailchi.mp/baace96461ae/ip8value-newsletter-innovative-solutions-for-respiratory-diseases",
          cn: "https://mp.weixin.qq.com/s/763KTNAqXhRlAfai99fm3A",
        },
        content:
          "The global burden of respiratory diseases, including lung cancer, asthma, chronic obstructive pulmonary disease (COPD), and pneumonia, is substantial, affecting millions of people worldwide and resulting in significant socioeconomic costs. According to recent estimates, lung cancer alone claims approximately 1.8 million lives annually, while asthma affects over 300 million people globally. The COVID-19 pandemic has further highlighted the urgent need for effective and safer treatments for respiratory diseases, with the global market for respiratory therapeutics projected to grow significantly in the coming years.",
        date: "24 December 2024",
        id: `_${uuid()}`,
        thumbnail: blog_24_dec_2024,
        title: "Innovative Solutions for Respiratory Diseases",
      },
      {
        link: {
          en: "https://mailchi.mp/fd987db86fb6/ip8value-newsletter-sustainable-energe",
          cn: "https://mp.weixin.qq.com/s/L3p-DG_wfIPPRiJ3UfLz5w",
        },
        content:
          "The development and implementation of sustainable technologies require addressing critical technical demands to ensure scalability, efficiency, and adaptability across diverse applications. These demands include advanced material science for energy efficiency, innovative chemical processes for waste conversion, robust engineering for renewable energy systems, and the integration of environmentally friendly components to minimize ecological impact.",
        date: "16 December 2024",
        id: `_${uuid()}`,
        thumbnail: blog_16_dec_2024,
        title: "Sustainable Energy",
      },
    ],
    null: "No blog at the moment. Please stay tuned!",
    subheader: "Explore our latest blog collection",
  },
  contact: {
    header: "Get in touch",
    subheader: "",
    content:
      "Contact us if you would like to find out more about our products or would like to request a demo.",
    action: "Contact Us",
  },
  contactForm: {
    header: "Contact Us",
    subheader:
      "Reach out to us on product features, demo requests, or if you just want a chat. We are also looking out for soliciting partners, collaborators, inventors, and investors.",
    optionPlaceholder: "What would you like to contact us about?*",
    optionLabel: "Contact purpose",
    options: [
      "Product Features",
      "Request A Demo",
      "Investment Opportunities",
      "Technical Support",
    ],
    action: "Submit",
  },
  events: {
    header: "Events",
    image: "",
    items: [
      {
        status: "completed",
        header: "",
        items: [
          {
            action: "Learn More",
            content: cncc_2024_content,
            date: "23 October 2024",
            path: "2024-10-23-cncc",
            thumbnail: cncc_2024_thumbnail,
            title: "CNCC 2024",
            venue: "Heng Dian, China",
          },
          {
            action: "Learn More",
            content: techinnovation_2024_content,
            date: "28 October 2024",
            path: "2024-10-28-techinnovation",
            thumbnail: techinnovation_2024_thumbnail,
            title: "TechInnovation 2024",
            venue: "Singapore",
          },
          {
            action: "Learn More",
            content: singapore_fintech_festival_2024_content,
            date: "06 November 2024",
            path: "2024-11-06-singapore-fintech-festival",
            thumbnail: singapore_fintech_festival_2024_thumbnail,
            title: "Singapore Fintech Festival 2024",
            venue: "Singapore",
          },
        ],
      },
      {
        status: "upcoming",
        header: "Upcoming Events",
        items: [],
      },
    ],
    null: "No events at the moment. Please stay tuned!",
    subheader:
      "Discover our journey at key events shaping the future of innovation",
  },
  hero: {
    slogan: "Intellectual Asset Management",
    subSlogan: "for Value Creation",
    header: ["Unlock", "IP Potential", "For", "Value Creation"],
    content:
      "Streamline and optimize IP management and commercialization processes.",
  },
  meta: {
    description:
      "Our platform creates a deep tech community that accelerates the communication in technology innovation, translation and commercialization.",
    title:
      "IP8Value: Intellectual Asset Management for Technology Innovation & Commercialization",
    url: "https://www.ip8value.com/",
  },
  overview: {
    header: "Overview",
    image: "",
    subheader: "Asia’s premier IP commercialization platform",
    body: "",
    items: [
      {
        image: overview_1,
        background:
          "linear-gradient(61deg, rgba(4, 104, 214, 0.18) 1.09%, rgba(225, 150, 198, 0.18) 32.33%, rgba(255, 190, 170, 0.18) 59.9%)",
        header: "Streamlined",
        content:
          "An end-to-end solution that seamlessly connects inventors and IP management offices, transforming technology submissions into marketable materials in minutes.",
      },
      {
        image: overview_2,
        background:
          "linear-gradient(90deg, rgba(255, 238, 238, 0.50) 0%, rgba(221, 239, 187, 0.50) 100%)",
        header: "Secure",
        content:
          "We separate the internal IP management system and the online marketplace, ensuring effective marketing of your IP without compromising confidentiality.",
      },
      {
        image: overview_3,
        background:
          "linear-gradient(90deg, rgba(224, 234, 252, 0.50) 0%, rgba(207, 222, 243, 0.50) 100%)",
        header: "Visibility",
        content:
          "Showcase your IP alongside the best universities, amplifying your reach and attracting the attention you deserve.",
      },
      {
        image: overview_4,
        background:
          "linear-gradient(90deg, rgba(31, 162, 255, 0.20) 0%, rgba(18, 216, 250, 0.20) 50%, rgba(166, 255, 203, 0.20) 100%)",
        header: "Monetization",
        content:
          "Our platform enables online licensing, sales, patent pools, and other diverse options to generate revenue from your IP assets.",
      },
    ],
    videos: {
      en: overview_video,
      zh: overview_video_chinese,
    },
  },
  value: {
    header: "Our Customer",
    image: "",
    subheader: "A useful IP platform for all",
    body: "Enabling sharing, collaborations and investment",
    items: [
      {
        backgroundColor: theme.palette.accent.blue,
        image: value_2,
        label: "Companies",
        unorderedItems: [
          "Efficiently manage IPs and capitalize on intangible assets.",
          "Collaborate with universities to build your technology edge.",
        ],
        value: 2,
      },
      {
        backgroundColor: theme.palette.accent.green,
        image: value_3,
        label: "Investors",
        unorderedItems: [
          "Identify start-ups and IPs from universities and research institutes for investment opportunities.",
        ],
        value: 3,
      },
      {
        backgroundColor: theme.palette.accent.orange,
        image: value_1,
        label: "University and Research Institutes",
        unorderedItems: [
          "Streamline IP management and innovation.",
          "Boost visibility for your research outcomes.",
          "Strengthen your presence in the tech commercialization ecosystem.",
        ],
        value: 0,
      },
      {
        backgroundColor: theme.palette.accent.red,
        image: value_4,
        label: "Talents and Innovators",
        unorderedItems: [
          "Discover the latest academic breakthroughs.",
          "Find the perfect technology to fuel your entrepreneurial journey.",
        ],
        value: 1,
      },
    ],
  },
  product: {
    header: "Product",
    subheader: "How it works",
    body: "A global platform for IP marketing, technology search and investment",
    items: [
      {
        button: {
          label: "Discover How It Works",
          color: theme.palette.accent.green,
          link: NAV_SUBROUTES.get("DOCS").PATH,
        },
        header: "Automate IP Marketing",
        images: [
          marketing_en_slides_01,
          marketing_en_slides_02,
          marketing_en_slides_03,
          marketing_en_slides_04,
          marketing_en_slides_05,
        ],
        video: marketing_en_video_01,
        video_url: "https://www.youtube.com/watch?v=Lmi3ap58swU",
        list_items: [
          "AI-Driven Tech Offer Generation: Harness the power of our enterprise-specific Large Language Model (LLM) to generate customized, readible technology offers effortlessly.",
          "Unified Data Ecosystem: Integrate cutting-edge technology and marketing insights into a single, streamlined platform for smarter, faster decisions.",
        ],
      },
      {
        button: {
          label: "Discover How It Works",
          color: theme.palette.accent.orange,
          link: NAV_SUBROUTES.get("DOCS").PATH,
        },
        header: "IP Analysis & Reporting",
        images: [],
        video: marketing_en_video_02,
        video_url: "https://www.youtube.com/watch?v=pz69PBNldKk",
        list_items: [
          "Real-Time, Actionable Insights: Stay ahead with dynamic, real-time reports that deliver the latest trends and opportunities.",
          "Strategic Market Intelligence: Unlock deep insights into technology landscapes, market demands, and mature marketing strategies.",
        ],
      },
      {
        button: {
          label: "Discover How It Works",
          color: theme.palette.accent.redLight,
          link: NAV_SUBROUTES.get("DOCS").PATH,
        },
        header: "7x24 Online IP Search",
        images: [
          marketing_en_slides_01,
          marketing_en_slides_02,
          marketing_en_slides_03,
          marketing_en_slides_04,
          marketing_en_slides_05,
        ],
        video: marketing_en_video_03,
        video_url: "https://www.youtube.com/watch?v=pz69PBNldKk",
        list_items: [
          "Conversational IP Discovery: Enjoy multi-round, interactive searches that refine results with every query.",
          "Seamless Database Integration: Access a vast network of IP data, expert knowledge, and more — all in one place.",
        ],
      },
      {
        button: {
          label: "Discover How It Works",
          color: theme.palette.accent.purpleLight,
          link: NAV_SUBROUTES.get("DOCS").PATH,
        },
        header: "Customer Demand Management",
        images: [
          marketing_en_slides_01,
          marketing_en_slides_02,
          marketing_en_slides_03,
          marketing_en_slides_04,
          marketing_en_slides_05,
        ],
        video: marketing_en_video_04,
        video_url: "https://www.youtube.com/watch?v=pz69PBNldKk",
        list_items: [
          "Data-Driven Demand Analysis: Identify and analyze customer needs with precision and clarity.",
          "Personalized Recommendations: Get tailored IP and expert suggestions designed to meet your specific goals and challenges.",
        ],
      },
    ],
  },
  solution: {
    header: "Our Solution",
    image: solution_image,
    subheader: "What we've done",
    body: "A global platform for IP marketing, technology search and investment",
    items: [
      {
        // image: solution_1,
        header: "Tools for Technology Translation",
        content:
          "IP management, IP offer generator,  document e-sign, open patent pool, open innovation and more.",
        // action: "Request for Demo",
      },
      {
        // image: solution_2,
        header: "Search Platform",
        content:
          "Search platform connecting universities, companies, investors, and inventors.",
        // action: "Request for Demo",
      },
      {
        // image: solution_3,
        header: "IP Database",
        content:
          "Ledger databases allow users to manage,  market, and search IPs with less effort.",
        // action: "Request for Demo",
      },
    ],
  },
  tech: {
    header: "Technology",
    image: tech_image,
    subheader: "Follow the trend",
    body: "Our IP platform accelerates the communication in technology innovation, translation and commercialization",
    items: [
      {
        image: lock_icon,
        header: "Fine-grained Access Control",
        content:
          "It provides different levels of access control. IP holders can collaborate with each other and decide which part of IP information can be viewed by public users.",
      },
      {
        image: search_icon,
        header: "Knowledge-based Search and Recommendation",
        content:
          "It facilitates fast and elastic search with a high matching accuracy, so that users can find desired and suitable IPs easily. It also recommends relevant and interesting IPs to users.",
      },
      {
        image: block_icon,
        header: "Blockchain and Verifiable Database",
        content:
          "It allows IP holders to store important and confidential information on the blockchain/verifiable databases as evidence with immutability and traceability.",
      },
      {
        image: nft_icon,
        header: "Non-Fungible Tokens",
        content:
          "It enables the IP holders to create NFTs associated with the IPs on the blockchain and transfer the ownership with investors.",
      },
      {
        image: contract_icon,
        header: "Trusted Contract Signing",
        content:
          "It offers a trusted document signing service for IP buyers and IP holders to sign contracts, which are witnessed by the blockchain.",
      },
    ],
  },
  resources: {
    header: "Resources",
    content: "",
    items: [
      {
        image: "",
        header: "How do I know the signers are authenticated?",
        content:
          "Our platform provides a variety of authentication methods to verify the signers’ identities, including passcode, two-factor authentication, and decentralized identity. We ensure that only qualified signers can sign on the document.",
      },
      {
        image: "",
        header: "How safe is my document?",
        content:
          "Your document is highly protected via standard encryption techniques, only qualified signers can open and read the content of the document.",
      },
      {
        image: "",
        header: "How do I verify the signed document?",
        content:
          "You can upload the original copy of the document to the frontend, our platform will retrieve the actions history of this document, which are recorded in the immutable storages, either verifiable database or blockchain.",
      },
    ],
  },

  partners: {
    header: "Partners",
    items: [
      // { image: partners_nus, name: "NUS", link: "https://nus.edu.sg/" },
      // {
      //   image: partners_soc,
      //   name: "NUS School of Computing",
      //   link: "https://www.comp.nus.edu.sg/",
      // },
      { image: partners_sbip, name: "SBIP", link: "https://sbip.sg/" },
      {
        image: partners_nusdbsys,
        name: "NUS DB System",
        link: "https://www.comp.nus.edu.sg/~dbsystem/",
      },
    ],
  },
  footer: {
    socialMedia: [
      // {
      //   icon: <RiTwitterFill size="1.8em" />,
      //   label: "",
      //   link: "",
      // },
      {
        icon: <RiLinkedinBoxFill size="1.8em" />,
        label: "",
        link: "https://www.linkedin.com/",
      },
      {
        icon: <RiFacebookCircleFill size="1.8em" />,
        label: "",
        link: "https://www.facebook.com/",
      },
    ],
    address:
      "National University of Singapore,11 Research Link, COM3, #B1-16, Singapore 119391",
    email: "contact@ip8value.com",
    copyright: `Copyright © ${new Date().getFullYear()} ip8value. All rights reserved`,
  },
};
