import { appBar, theme } from "../../../utils/theme";

const optionContainer = {
  alignItems: "center",
  borderRadius: 1,
  cursor: "pointer",
  display: "flex",
  pb: `8px !important`,
  pl: `12px !important`,
  pr: `12px !important`,
  pt: `8px !important`,
};

const optionLabel = {
  fontSize: theme.typography.fontSize * 0.88,
  fontWeight: 700,
  flex: 1,
  letterSpacing: 1,
  mr: 4,
  ml: 3,
  textAlign: "start",
};

export const styles = {
  accordionLabel: {
    ...optionLabel,
    color: theme.palette.grays[700],
    fontSize: theme.typography.fontSize * 0.75,
    fontWeight: 700,
    letterSpacing: 1,
    ml: 6,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  optionContainerActive: {
    ...optionContainer,
    backgroundColor: theme.palette.grays[200],
    color: theme.palette.primary.main,
  },
  optionContainerButton: {
    ...optionContainer,
    justifyContent: "start",
    "&:hover": {
      backgroundColor: theme.palette.grays[200],
    },
  },
  optionContainerInactive: {
    ...optionContainer,
    color: theme.palette.grays[500],
    "&:hover": {
      backgroundColor: theme.palette.grays[200],
    },
  },
  optionGroupContainer: {
    pb: 3,
    pt: 3,
  },
  optionLabelActive: {
    ...optionLabel,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  optionLabelInactive: {
    ...optionLabel,
    color: theme.palette.grays['black'],
  },
  optionLink: {
    color: "inherit",
    textDecoration: "none",
  },
  sideMenu: {
    borderRight: `1px solid ${theme.palette.grays[300]}`,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
    overflowY: "auto",
    pl: 2,
    pr: 2,
    position: "sticky",
    top: 0,
    // top: appBar.height,
    width: 280,
  },
};
