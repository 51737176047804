import { theme } from "../../utils/theme";

export const styles = {
  aboutRoot: {
    alignItems: "center",
    backgroundColor: theme.palette.grays[100],
    color: theme.palette.grays.black,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
  },
  background: {
    filter: "brightness(0.7) blur(0) grayscale(1)",
    height: "100%",
    right: { xs: 0, md: 0 },
    top: { xs: 0, md: 0 },
    position: "absolute",
    width: { xs: 600, md: "100%" },
    zIndex: 0,
  },
  body: {
    alignItems: "center",
    display: "flex",
    gap: 8,
    justifyContent: "space-between",
  },
  bodyText: {
    color: theme.palette.grays[800],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.13,
    },
    fontWeight: 300,
    mb: 1,
    mt: { xs: 8, md: 8 },
    textTransform: "none",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  image: {
    height: "auto",
    width: "100%",
  },
  item: {
    display: "flex",
    overflow: "hidden",
    py: { xs: 0, md: 2 },
  },
  itemBullet: {
    alignItems: "center",
    display: "flex",
    backgroundColor: theme.palette.grays.black,
    borderRadius: "50%",
    color: theme.palette.grays["white"],
    fontSize: {
      xs: theme.typography.fontSize * 0.88,
      md: theme.typography.fontSize * 1,
    },
    fontWeight: 500,
    height: { xs: 30, md: 36 },
    justifyContent: "center",
    minWidth: { xs: 30, md: 36 },
  },
  itemBody: {
    color: theme.palette.grays[900],
    fontSize: {
      xs: theme.typography.fontSize * 0.88,
      md: theme.typography.fontSize * 1,
    },
    textAlign: { xs: "left", md: "left" },
  },
  itemContent: {
    alignItems: { xs: "center", md: "center" },
    display: "flex",
    gap: 3,
    justifyContent: { xs: "start", md: "start" },
    px: { xs: 4, md: 8 },
    pb: 11,
  },
  itemHeader: {
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.25,
      fontWeight: 700,
    },
    textAlign: "center",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
