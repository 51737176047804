import { Box, Fade, Grid, Grow, Stack, Typography } from "@mui/material";
import i18n from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";

import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";
import { addLeadingZero } from "../../utils/formUtil";

const About = () => {
  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const { t } = useTranslation();

  return (
    <Box sx={styles.aboutRoot}>
      <SectionLayout id={NAV_SUBROUTES.get("ABOUT").ID}>
        <Stack ref={setRef} sx={styles.body}>
          <Fade in={visible} timeout={600}>
            <Stack>
              <Header data={content.about} />

              <Typography sx={styles.bodyText}>
                {t(content.about.content)}
              </Typography>
            </Stack>
          </Fade>

          <img
            alt={content.about.header}
            src={
              i18n.language === "en"
                ? content.about.image.en
                : content.about.image.cn
            }
            style={styles.image}
          />

          <Typography sx={styles.itemHeader}>
            {t(content.about.itemHeader)}
          </Typography>

          <Grid container direction="row" ref={setRef} spacing={0}>
            {content.about?.items.map((item, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Grow in={visible} timeout={1000 + index}>
                  <Box sx={{ ...styles.item }}>
                    <Fade in={visible} timeout={600 + index}>
                      <Box sx={styles.itemContent}>
                        <Box sx={styles.itemBullet}>
                          {addLeadingZero(index + 1)}
                        </Box>

                        <Typography sx={styles.itemBody}>{t(item)}</Typography>
                      </Box>
                    </Fade>
                  </Box>
                </Grow>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </SectionLayout>
    </Box>
  );
};

export default About;
