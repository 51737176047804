import { Box, Button, Grow, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { theme } from "../../../utils/theme";

import { styles } from "./style";

const BlogItem = ({ data, index, visible }) => {
  return (
    <Box sx={styles.item}>
      <Grow key={index} in={visible} timeout={1000 + index * 500}>
        <Box sx={styles.itemImageContainer}>
          <Box
            sx={{
              ...styles.itemImage,
              background: `url(${data.thumbnail})`,
            }}
          ></Box>
        </Box>
      </Grow>

      <Stack justifyContent={"space-between"} spacing={10} sx={styles.itemBody}>
        <Stack spacing={3}>
          <Typography sx={styles.itemText}>{data.date}</Typography>
          {data.link ? (
            <Typography sx={styles.itemHeaderInactive}>{data.title}</Typography>
          ) : (
            <Typography sx={styles.itemHeaderDisabled}>{data.title}</Typography>
          )}

          <Typography sx={styles.itemContent}>{data.content}</Typography>
        </Stack>

        <Box sx={styles.linkContainer}>
          <Link
            to={data.link["en"]}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            <Button
              color={"primary"}
              sx={styles.button}
              variant={"contained"}
            >
              English Edition
            </Button>
          </Link>

          {/* <Divider flexItem orientation="vertical" sx={styles.divider} /> */}

          <Link
            to={data.link["cn"]}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            <Button
              color={"primary"}
              sx={{ ...styles.button, color: theme.palette.primary.main }}
              variant={"outlined"}
            >
              中文版
            </Button>
          </Link>
        </Box>
      </Stack>
    </Box>
  );
};

export default BlogItem;
