import { appBar, bodyWidth, theme } from "../../../utils/theme";

const optionContainer = {
  alignItems: "center",
  borderRadius: 1,
  cursor: "pointer",
  display: "flex",
  pb: `8px !important`,
  pl: `12px !important`,
  pr: `12px !important`,
  pt: `8px !important`,
};

const optionLabel = {
  fontSize: theme.typography.fontSize * 0.88,
  fontWeight: 700,
  flex: 1,
  letterSpacing: 1,
  mr: 4,
  ml: 3,
  textAlign: "start",
};

export const styles = {
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  dialogBackground: {
    filter: "brightness(0.9) blur(2px)",
    height: "100vh",
    right: 0,
    top: 0,
    overflow: "hidden",
    position: "absolute",
    width: "100vw",
    zIndex: 0,
  },
  dialogHeader: {
    alignItems: "center",
    color: theme.palette.grays[700],
    display: "flex",
    height: appBar.height,
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    width: bodyWidth.width,
  },
  dialogHeaderContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  },
  dialogListContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    pb: 2,
    pt: 2,
  },
  dialogVideo: {
    height: "100%",
    left: 0,
    position: "absolute",
    width: { xs: "inherit", md: "100%" },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  headerIcon: {
    alignItems: "center",
    backgroundColor: theme.palette.grays.black,
    // backgroundColor: theme.palette.grays.white,
    border: `0px solid ${theme.palette.grays[200]}`,
    borderRadius: "50%",
    boxShadow: "0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)",
    color: theme.palette.grays.white,
    // color: theme.palette.primary.main,
    display: "flex",
    height: 40,
    justifyContent: "center",
    minWidth: 40,
  },
  icon: {
    alignItems: "center",
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    ml: 3,
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  navMenuContainer: {
    alignItems: "center",
    bottom: 68,
    color: theme.palette.grays.black,
    display: { md: "none", xs: "flex" },
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    right: 12,
  },
  navText: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 500,
    letterSpacing: 1.2,
    mt: 2,
    mb: 2,
    ml: 9,
    textTransform: "inherit",
  },
  accordionLabel: {
    ...optionLabel,
    color: theme.palette.grays[700],
    fontSize: theme.typography.fontSize * 0.75,
    fontWeight: 700,
    letterSpacing: 1,
    ml: 6,
  },
  optionContainerActive: {
    ...optionContainer,
    backgroundColor: theme.palette.grays[200],
    color: theme.palette.primary.main,
  },
  optionContainerButton: {
    ...optionContainer,
    color: `${theme.palette.grays[800]} !important`,
    justifyContent: "start",
    "&:hover": {
      backgroundColor: theme.palette.grays[200],
    },
  },
  optionContainerInactive: {
    ...optionContainer,
    color: theme.palette.grays[500],
    "&:hover": {
      backgroundColor: theme.palette.grays[200],
    },
  },
  optionGroupContainer: {
    pb: 3,
    pt: 3,
  },
  optionLabelActive: {
    ...optionLabel,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  optionLabelInactive: {
    ...optionLabel,
    color: theme.palette.grays[900],
  },
  optionLink: {
    color: "inherit",
    textDecoration: "none",
  },
};
