import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import BackgroundSolarAnimated from "../../components/BackgroundSolarAnimated";
import BlogItem from "../../components/blogPageComponents/BlogItem";
import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../../components/SlideTransition";

import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { BLOG_LANGUAGE_OPTIONS } from "../../utils/optionUtils";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const Blog = () => {
  const [setRef, visible] = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(
    BLOG_LANGUAGE_OPTIONS.values().next().value.VALUE
  );

  const handleScrollToItem = (id) => () => {
    document.querySelector(`#${id}`)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Stack
      direction={"row"}
      spacing={0}
      alignItems={"start"}
      ref={setRef}
      sx={styles.root}
    >
      <Box sx={styles.background}>
        <Box sx={styles.backgroundOverlay} />
      </Box>

      <SectionLayout id={NAV_SUBROUTES.get("BLOG").ID} style={{ pt: 0 }}>
        {/* <SlideTransition appear={visible} timeout={700}> */}
        <Stack spacing={4} justifyContent={"center"} sx={styles.header}>
          <Typography sx={styles.headerText}>
            {t(content.blog.header)}
          </Typography>

          <Typography sx={styles.bodyText}>
            {t(content.blog.subheader)}
          </Typography>
        </Stack>
        {/* </SlideTransition> */}

        <Box sx={styles.splitLayout}>
          <Stack sx={styles.scrollableColumn}>
            {content.blog.items && content.blog.items.length > 0 ? (
              content.blog.items.map((item, idx) => {
                return (
                  <Box id={item.id} key={idx} sx={styles.item}>
                    <BlogItem data={item} visible={true} />
                  </Box>
                );
              })
            ) : (
              <Box sx={styles.nullContainer}>{content.blog.null}</Box>
            )}
          </Stack>

          <Stack sx={styles.stickyColumn}>
            <Typography sx={styles.indexItemTitle}>
              {t("Recent Blogs")}
            </Typography>

            <Stack sx={styles.itemsContainer}>
              {content.blog.items.map((item, index) => {
                return (
                  <Box sx={styles.indexItem}>
                    <Box sx={styles.indexItemBullet} />
                    <Typography
                      key={index}
                      onClick={handleScrollToItem(item.id)}
                      sx={styles.indexItemText}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>

            <Divider />
          </Stack>
        </Box>
      </SectionLayout>
    </Stack>
  );
};

export default Blog;
